import {NgModule} from '@angular/core'
import {CommonModule} from '@angular/common'
import {NextArrowComponent} from './next-arrow/next-arrow.component'
import {ScrollDirective} from './scroll/scroll.directive'
import {ScrollDownDirective} from './scroll/scroll-down.directive'


@NgModule({
  declarations: [
    NextArrowComponent,
    ScrollDirective,
    ScrollDownDirective
  ],
  imports: [
    CommonModule
  ],
  exports: [
    NextArrowComponent,
    ScrollDirective,
    ScrollDownDirective
  ]
})
export class AkiruCommonModule {
}
