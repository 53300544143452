import {Component} from '@angular/core'
import {StateService} from '../services/state.service'
import {HeaderFooterBaseComponent} from '../application/header-footer-base/header-footer-base.component'
import {environment} from '../../environments/environment'
import {trigger, state, style, transition, animate} from '@angular/animations'

@Component({
  selector: 'aku-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  animations: [
    trigger('animator', [
      // ...
      state('start', style({
        'background-color': 'transparent'
      })),
      state('about', style({
        opacity: 1,
        'background-color': '#f2f2f2'
      })),
      transition('* => about', [
        animate('1s')
      ]),
      transition('about => *', [
        animate('1s')
      ])
    ])
  ]
})
export class HeaderComponent extends HeaderFooterBaseComponent {

  public skip: boolean = environment.production

  constructor(stateService: StateService) {
    super(stateService)
  }
}
