import {Injectable} from '@angular/core'
import {BehaviorSubject} from 'rxjs'

@Injectable({
  providedIn: 'root'
})
export class StateService {

  public state$: BehaviorSubject<string> = new BehaviorSubject<string>('')
}
