import {RouterModule, Route} from '@angular/router'
import {NgModule} from '@angular/core'
import {HeaderResolver} from './application/header-resolver/header.resolver'
import {DummyComponent} from './0-start/dummmy/dummy.component'

export const routes: Route[] = [
  {
    path: '',
    component: DummyComponent,
    resolve: {state: HeaderResolver},
    data: {animationState: 'start'}
  },
  {
    path: 'akiru',
    resolve: {state: HeaderResolver},
    loadChildren: () => import('./1-akiru/akiru.module').then(m => m.AkiruModule),
    data: {animationState: 'akiru'}
  },
  {
    path: 'about',
    resolve: {state: HeaderResolver},
    loadChildren: () => import('./2-about/about.module').then(m => m.AboutModule),
    data: {animationState: 'about'}
  }
]

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    enableTracing: false,
    useHash: true,
    scrollPositionRestoration: 'disabled'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
