<div [@animator]="state" [ngSwitch]="state" class="holder">
  <div *ngSwitchDefault class="header home">
    <ng-container *ngTemplateOutlet="myMenu"></ng-container>
  </div>
  <div *ngSwitchCase="'akiru'" class="header akiru">
    <ng-container *ngTemplateOutlet="title"></ng-container>
  </div>
  <div *ngSwitchCase="'about'" class="header about">
    <!-- Add this to center the title -->
    <span class="material-symbols-outlined spacer">menu</span>
    <div class="akiru-filler"></div>
    <ng-container *ngTemplateOutlet="title"></ng-container>
    <div class="akiru-filler"></div>
    <ng-container *ngTemplateOutlet="myMenu"></ng-container>
  </div>
</div>


<ng-template #title>
  <div class="clickable">
    <div [routerLink]="['/']" class="title">Akiru studios</div>
  </div>
</ng-template>
<ng-template #myMenu>
  <div [matMenuTriggerFor]="menu" aria-controls="menu" aria-haspopup="true" aria-label="Huvudmeny" class="menu">
    <span class="material-symbols-outlined">
      menu
    </span>
  </div>
  <mat-menu #menu="matMenu">
    <button
      role="menuitem"
      [routerLink]="['/', 'about', 'how-we-work']"
      mat-menu-item>Så jobbar vi
    </button>
    <button
      role="menuitem"
      [routerLink]="['/', 'about', 'about-us']"
      mat-menu-item>Om oss
    </button>
    <button
      role="menuitem"
      [routerLink]="['/', 'about','clients']"
      mat-menu-item>Våra klienter
    </button>
    <button
      role="menuitem"
      [routerLink]="['/', 'about', 'we-are-akiru']"
      mat-menu-item>Vi är Akiru
    </button>
  </mat-menu>
</ng-template>
