import {Component, Renderer2, OnInit} from '@angular/core'
import {RouterOutlet} from '@angular/router'

@Component({
  selector: 'aku-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  constructor(private renderer: Renderer2) {
  }

  /**
   * Returns the value of the 'animationState' property set in the router
   * configuration.
   */
  prepareRoute(outlet: RouterOutlet): string {
    return outlet.activatedRouteData['animationState']
  }

  public ngOnInit(): void {
    setTimeout(() => {
      this.renderer.setStyle(document.body,
        //transparent url('/assets/the_nice_trees_big.jpg') no-repeat fixed center center
        'background', `transparent url('/assets/the_nice_trees_big.jpg') no-repeat fixed center center`)
      this.renderer.setStyle(document.body, 'background-size', 'cover')
    }, 500)
  }
}



