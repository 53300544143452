import {Directive, HostListener} from '@angular/core'
import {Router} from '@angular/router'

@Directive({
  selector: '[akuScroll]'
})
export class ScrollDirective {


  /**
   * We need to know when we are at the top.
   */
  public top = 0

  constructor(private router: Router) {
  }

  @HostListener('window:wheel', ['$event'])
  public mouse(event: WheelEvent): void {
    if (event.deltaY <= 0) {
      if (this.top === 0) {
        this.router.navigate(['/']).then()
      }
    }
  }

  @HostListener('document:scroll', ['$event'])
  public scroll(event: any) {
    this.top = event.target.documentElement.scrollTop
  }
}
