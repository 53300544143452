import {Directive, HostListener} from '@angular/core'
import {Router} from '@angular/router'

@Directive({
  selector: '[akuScrollDown]'
})
export class ScrollDownDirective {

  constructor(public router: Router) {
  }

  @HostListener('window:scroll', ['$event'])
  public scroll(event: any): void {
    if (event.currentTarget.scrollY > 0 && event.target.documentElement.scrollTop < 20) {
      this.router.navigate(['about', 'how-we-work']).then()
    }
  }
}
