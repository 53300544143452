import {Component} from '@angular/core'
import {StateService} from '../services/state.service'
import {HeaderFooterBaseComponent} from '../application/header-footer-base/header-footer-base.component'

@Component({
  selector: 'aku-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent extends HeaderFooterBaseComponent {

  constructor(stateService: StateService) {
    super(stateService)
  }
}
