import {Component, OnInit} from '@angular/core'
import {StateService} from '../../services/state.service'

@Component({
  selector: 'aku-header-footer-base',
  template: ''
})
export class HeaderFooterBaseComponent implements OnInit {

  public state = '/'

  constructor(private stateService: StateService) {
  }

  public ngOnInit(): void {
    this.stateService.state$.subscribe({
      next: (path: string) => {
        this.state = path.split('/')[1] || 'start'
      }
    })
  }

}
