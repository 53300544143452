<div akuScrollDown class="page-top">
  <div class="akiru-filler"></div>
  <div class="main">
    <div class="akiru-filler"></div>
    <img alt="logo" src="./assets/AKIRU-STUDIOS_logo-horiz-black_RGB.png">
    <div class="akiru-filler"></div>
  </div>
  <div class="akiru-filler"></div>
  <aku-next-arrow [routerLink]="['about', 'how-we-work']" [white]="true" text="så här jobbar vi"></aku-next-arrow>
</div>
