import {Injectable} from '@angular/core'
import {Resolve, RouterStateSnapshot, ActivatedRouteSnapshot} from '@angular/router'
import {Observable, of} from 'rxjs'
import {StateService} from '../../services/state.service'

@Injectable({
  providedIn: 'root'
})
export class HeaderResolver implements Resolve<string> {
  constructor(private stateService: StateService) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<string> {
    this.stateService.state$.next(state.url)
    return of(state.url)
  }
}
