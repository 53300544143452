import {NgModule} from '@angular/core'
import {BrowserModule} from '@angular/platform-browser'
import {AppComponent} from './app.component'
import {HeaderComponent} from './header/header.component'
import {FooterComponent} from './footer/footer.component'
import {MatIconModule} from '@angular/material/icon'
import {MatMenuModule} from '@angular/material/menu'
import {BrowserAnimationsModule} from '@angular/platform-browser/animations'
import {MatButtonModule} from '@angular/material/button'
import {AppRoutingModule} from './app-routing.module'
import {StartComponent} from './0-start/start.component'
import {HeaderFooterBaseComponent} from './application/header-footer-base/header-footer-base.component'
import {AkiruCommonModule} from './common/common.module'
import {DummyComponent} from './0-start/dummmy/dummy.component'

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    StartComponent,
    HeaderFooterBaseComponent,
    DummyComponent
  ],
  imports: [
    AppRoutingModule,
    BrowserModule,
    BrowserAnimationsModule,
    MatIconModule,
    MatMenuModule,
    MatButtonModule,
    AkiruCommonModule
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
